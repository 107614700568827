<template>
    <div>
        <modal :show="show" @hide="closeModal" size="lg">
            <div slot="header" class="flex w-full flex__justify-between pr-5">
                <h2>Company contact book</h2>

                <div class="flex">
                    <input id="table-search-input" v-model="filter" type="text" placeholder="Search" class="border-0 pl-1" autocomplete="off" @keyup.enter="pushSearch">
                    <button class="mr-4" @click="$emit('open-add-contact')">
                        <svg-importer icon-name="icons/add-user" />
                    </button>
                    <multiselect
                        v-model="localOwner"
                        :options="organizations"
                        track-by="id"
                        label="name"
                        placeholder="Select owner"
                        :disabled="!isAdmin || loading"
                        class="w-48"
                    />
                </div>
            </div>

            <div slot="body" class="-mx-6 overflow-auto max-h-50 h-50vh">
                <table class="table w-full border-b address-table">
                    <tr class="header-column">
                        <th v-for="({ sortKey, name, }, index) in columns" :key="`address-book-column_${index}`">
                            <span>
                                {{ name }}
                            </span>
                            <span v-if="sortKey" type="button" class="mr-1 cursor-pointer"  @click="sort(sortKey)">
                              <svg-importer v-if="table.sortKey !== sortKey" :key="`${sortKey}_default`" icon-name="icons/table-sort" />
                              <svg-importer v-else-if="currentSort(1)" :key="`${sortKey}_up`" icon-name="icons/table-sort-up" />
                              <svg-importer v-else-if="currentSort(-1)" :key="`${sortKey}_down`" icon-name="icons/table-sort-down" />
                            </span>
                        </th>
                    </tr>

                    <template v-if="loading">
                        <loading-rows v-for="n in loadingRows" :key="n" :columns="loadingRowsColumns" />
                    </template>

                    <template v-else>
                        <tr
                            v-for="(contact, index) in contacts"
                            :key="'contact-row'+ index"
                            @click="markContact(contact)"
                            v-on:dblclick="editContact(contact)"
                        >

                            <td class="px-4 text-center">
                                <input type="radio" v-model="selectedContact" :value="contact">
                            </td>
                            <td :title="contact.name" class="overflow-truncate-long">{{ contact.name || '-' }}</td>
                            <td :title="contact.phone">{{ contact.phone || '-' }}</td>
                            <td :title="contact.email">{{ contact.email || '-'}}</td>
                            <td :title="getAddressesString(contact.address)">{{ getAddressesString(contact.address) }}</td>
                            <td>
                                <button @click="showDeleteModal(contact)">
                                    <svg-importer icon-name="icons/close" width="18" />
                                </button>
                            </td>
                        </tr>
                        <tr v-show="!contacts.length" class="text-center">
                            <td colspan="8">
                                No results
                            </td>
                        </tr>
                    </template>
                </table>
            </div>

            <div slot="footer" class="flex flex__column w-full">
                <pagination
                    :table="table"
                    class="mb-4 address-book-pagination"
                    @go-to-prev="goToPrev"
                    @go-to-page="goToPage"
                    @go-to-next="goToNext"
                    @page-size="updatePageSize"
                />

                <div class="flex flex__justify-between w-full">
                    <div class="flex flex-grow">
                        <button
                            v-text="`Cancel`"
                            class="ml-auto mr-4 text-sm text-red-500"
                            @click="closeModal"
                        />

                        <button
                            v-text="`Select`"
                            class="btn"
                            :disabled="!selectedContact"
                            @click="$emit('choose')"
                        />
                    </div>
                </div>
            </div>
        </modal>
        <delete-modal
            :show="isShowDeleteModal"
            :selectedObject="selectedToDeleteContact"
            name="contact"
            @hide="hideDeleteModal"
            @delete="deleteContact"
        />
    </div>
</template>
<script>
import { paginationLinksReducer, TABLE_PAGINATION_DEFAULT } from './AddressBook.vue';
import LoadingRows from './LoadingRows.vue';
import Pagination from './AddressBookPagination.vue';

import axios from 'axios';
import { requestErrors } from '~/utils/errors';

export default {
    props: {
        show: Boolean,
        owner: {
            type: Object,
            default: () => null,
        },
        selectedContact: {
            type: Object,
            default: () => null,
        },
        organizations: {
            type: Array,
            default: () => [],
        },
    },

    components: { LoadingRows, Pagination },

    model: {
        prop: 'owner',
        event: 'update:owner',
    },

    data() {
        return {
            loading: false,
            table: { ... TABLE_PAGINATION_DEFAULT },
            columns: [
                { id: 'check', name: '' },
                { id: 'full_name', name: 'Full name', sortKey: 'name' },
                { id: 'phone', name: 'Phone', sortKey: 'phone' },
                { id: 'email', name: 'Email', sortKey: 'email' },
                { id: 'default_contact_for', name: 'Default contact for', sortKey: 'contact_for' },
                { id: 'close', name: '' },
            ],
            contacts: [],
            loadingRows: new Array(5),
            selectedToDeleteContact: null,
            isShowDeleteModal: false,
            endpoint: this.$apiUrl.addressBook.contact,
            filter: null,
        }
    },

    watch: {
        owner(val) {
            this.resetTable();

            if (!val || !this.show) {
                return;
            }

            this.fetchContacts(val);
        },
        show(val) {
            this.resetTable();

            if (!val) {
                return;
            }

            this.fetchContacts();
        },
    },

    computed: {
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        localOwner: {
            set(value) {
                this.$emit('update:owner', value);
            },
            get() {
                return this.owner;
            },
        },
        loadingRowsColumns() {
            return this.columns.map(col => col.id);
        },
    },

    methods: {
        showDeleteModal(data) {
            this.selectedToDeleteContact = data;
            this.isShowDeleteModal = true;
        },
        hideDeleteModal() {
            this.selectedToDeleteContact = null;
            this.isShowDeleteModal = false;
        },
        async deleteContact() {
            if (!this.selectedToDeleteContact) {
                return;
            }

            try {
                await axios.delete(`${this.endpoint}/${this.selectedToDeleteContact.id}`);

                this.$snotify.success('Contact removed');

                this.fetchContacts();
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }

            this.hideDeleteModal();
        },
        resetTable() {
            this.table = { ...TABLE_PAGINATION_DEFAULT };
        },
        goToPrev() {
            this.table.current_page--;

            this.fetchContacts();
        },
        goToPage(page) {
            if (this.table.current_page === +page) {
                return;
            }

            this.table.current_page = +page;

            this.fetchContacts();
        },
        goToNext() {
            this.table.current_page++;

            this.fetchContacts();
        },
        updatePageSize(value) {
            this.table.per_page = value;
            this.table.current_page = 1;

            this.fetchContacts();
        },
        currentSort (dir) {
            return this.table.sortOrder === dir;
        },
        pushSearch() {
            this.fetchContacts();
        },
        sort(sortKey) {
            this.table.sortOrder = this.table.sortOrder === 1 ? -1 : 1;
            this.table.sortKey = sortKey;
            console.log(this.table)
            this.fetchContacts();
        },
        closeModal() {
            this.$emit('hide');
        },
        markContact(event) {
            this.$emit('click', event);
        },
        editContact(event) {
            this.$emit('dblclick', event);
        },
        getContactBookParams() {
            return `?organization_id=${this.owner.id}`
                + `&pageSize=${this.table.per_page}`
                + `&page=${this.table.current_page}`
                + '&include=addresses'
                + `&sortOrder=${this.table.sortOrder}`
                + (this.table.sortKey ? `&sortKey=${this.table.sortKey}` : '')
                + (this.filter ? `&filter[search]=${this.filter}` : '');
        },
        getAddressesString(addresses) {
            return addresses?.map(address => address.name).join(', ') || '-';
        },
        async fetchContacts() {
            if (!this.loading) {
                this.loading = true;
            }

            try {
                console.log(this.getContactBookParams());
                const { data } = await axios.get(`${this.$apiUrl.addressBook.contact}/${this.getContactBookParams()}`);

                data.meta.links = data.meta.links.reduce(paginationLinksReducer, []);
                this.table = { ...data.meta, sortKey: this.table.sortKey, sortOrder: this.table.sortOrder };

                this.contacts = data.data;
            } catch (error) {
                console.error(error);
                this.resetTable();
                this.contacts = [];
            }

            this.loading = false;
        },
    },
    mounted() {
        this.table.sortOrder = -1;
        this.table.sortKey = '';
    }
}
</script>

<style>
.header-column {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
}
</style>
